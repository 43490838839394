.container_home {
  position: relative;
  width: 100%;
  height: 100%;
  min-height: calc(100vh - 64px);
  display: flex;
  flex-direction: column;
  align-items: center;
  background-image: url("../../asset/AgainFef.jpg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
.bg_opac {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
}
.background_home {
  width: 100%;
  height: 100%;
  .image_home {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
.container_title {
  position: relative;
  width: 100%;
  height: fit-content;
  top: 50%;
  left: 0;
  color: $main-color;
  padding: 10px;
  font-size: 45px;
  text-align: center;
  opacity: 0;
  animation: appear 0.4s ease-out 0.3s forwards;
  h1:nth-child(1) {
    color: transparent;
    -webkit-text-stroke: 2px $main-color;
  }
  h1:nth-child(2) {
    color: $main-color;
    animation: animate 4s ease-in-out infinite;
  }
  .title {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%);
    font-family: $font-test;
    letter-spacing: 2px;
  }
}
.home_horaire {
  position: absolute;
  @include ColCenterXY;
  top: 40%;
  transform: translateY(-10%);
  color: white;
  text-align: center;
  font-size: 14px;
  opacity: 0;
  animation: appear 0.6s ease-out 0.5s forwards;
  p:first-child {
    font-weight: 600;
    margin-bottom: 20px;
  }
  .horaire_title {
    margin-bottom: 15px;
    h3 {
      // color: $main-color;
      font-size: 16px;
      padding-bottom: 5px;
      border-bottom: 1px solid white;
    }
  }
  p {
    opacity: 0;
    letter-spacing: 1px;
    margin-bottom: 10px;
    animation: appear 0.8s ease-out 0.9s forwards;
  }
  .horaire_close {
    @include ColCenterXY;
    opacity: 0;
    margin-bottom: 15px;
    animation: appear 0.8s ease-out 0.9s forwards;

    h3 {
      //color: $main-color;

      text-transform: uppercase;
      letter-spacing: 2px;
      font-size: 16px;
      margin-bottom: 10px;
      // font-weight: 500;
    }
  }
}

@keyframes appear {
  100% {
    opacity: 1;
  }
}
// BEEr effect
@keyframes animate {
  0%,
  100% {
    clip-path: polygon(
      0% 45%,
      16% 44%,
      33% 50%,
      54% 60%,
      70% 61%,
      84% 59%,
      100% 52%,
      100% 100%,
      0% 100%
    );
  }

  50% {
    clip-path: polygon(
      0% 60%,
      15% 65%,
      34% 66%,
      51% 62%,
      67% 50%,
      84% 45%,
      100% 46%,
      100% 100%,
      0% 100%
    );
  }
}
@media screen and (min-width: 768px) {
  .container_title {
    .title {
      letter-spacing: 6px;
    }
  }
  .home_horaire {
    border-radius: 21% 79% 70% 30% / 25% 42% 58% 75%;
    background-color: $test;
    font-size: 18px;
  }
}
@media screen and (min-width: 1024px) {
  .container_title {
    font-size: 47px;
    .title {
      letter-spacing: 7px;
    }
  }
  .home_horaire {
    font-size: 18px;
    p {
      margin-bottom: 20px;
    }
    .horaire_title {
      margin-bottom: 20px;

      h3 {
        font-size: 22px;
      }
    }
  }
}
