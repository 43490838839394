.container_contact {
  position: relative;
  @include ColCenterXY;
  width: 100%;
  height: 100%;
  color: $main-color;
  background-color: white;
}
.contact_opac {
  position: relative;
  width: 100%;
  height: calc(100vh - 64px);
  background-image: url("../../asset/toilette.jpg");
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  font-size: 21px;
  color: $main-color;
  .opac {
    position: absolute;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    background-color: rgba(0, 0, 0, 0.6);
  }
}
.container_reseau_map {
  width: 100%;
  height: fit-content;
}
.container_map {
  position: relative;
  z-index: 5;
  width: 100%;
  height: 400px;
  padding-bottom: 30px;
  margin: 0 auto;
}
.map {
  width: 100%;
  height: 100%;
  border: 0;
}
.container_us_contact {
  width: 100%;
  overflow-x: hidden;
  overflow-y: hidden;
  scroll-margin-top: 64px;
  height: calc(100vh - 64px);
  padding-top: 25px;
  color: white;
  background: linear-gradient(
      310deg,
      rgba(90, 103, 90, 0.6) 10%,
      rgba(2, 0, 36, 0.6) 80%
    ),
    url("../../asset/contactnew.JPG");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-attachment: fixed;
  @include ColCenterXY;
  h2 {
    text-transform: uppercase;
    padding-bottom: 25px;
    letter-spacing: 2px;
    font-size: 1.188em;
    text-align: center;
    line-height: 30px;
  }
}
.container_reseau {
  position: relative;
  z-index: 5;
  width: 100%;
  height: fit-content;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-self: center;
  font-size: 18px;
  .reseau {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;
    span:first-of-type {
      margin-bottom: 10px;
      font-weight: 700;
    }
    a {
      text-decoration: none;
      color: white;
      font-family: $lacoop;
      &:hover {
        color: $main-color;
      }
    }
  }
}
@media screen and (min-width: 768px) {
  .container_contact {
    justify-content: center;
    h2 {
      font-size: 1.9em;
      padding-bottom: 50px;
    }
  }
  .container_map {
    width: 700px;
    height: 100%;
  }
}
@media screen and (min-width: 1024px) {
  .container_contact {
    height: calc(100vh - 64px);
  }
  .container_reseau_map {
    width: 100%;
    max-width: 1100px;
    // height: 100%;
    display: flex;
    flex-direction: row-reverse;
    justify-content: center;
    align-items: center;
  }
  .container_reseau {
    width: 30%;
  }
  .container_map {
    width: 60%;
    height: 350px;
  }
}
@media screen and (min-width: 1440px) {
  .container_contact {
    scroll-margin-top: 0;
    // height: calc(100vh - 64px);
    // h2 {
    // //   padding-bottom: 0px;
    // }
  }
  .container_map {
    width: 60%;
    height: 350px;
    padding: 0;
  }
}
