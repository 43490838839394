.container_menu {
  width: 100%;
  height: calc(100vh - 64px);
}
.section_menu {
  width: 100%;
  min-height: 100%;
  z-index: 1;
  color: white;
  padding: 20px 10px;
  background-image: url("../../asset/meal.jpg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-attachment: fixed;
  position: relative;
  &::after {
    content: " ";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgb(0, 0, 0, 0.9);
    z-index: 1;
    opacity: 0.6;
    background-size: cover;
  }
}
//NAV
.container_nav {
  width: 100%;
  height: fit-content;
  position: relative;
  z-index: 5;
  margin-bottom: 50px;
}
.menu_nav {
  max-width: 1100px;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  list-style-type: none;
  margin: 0 auto;

  li {
    cursor: pointer;
    background-color: transparent;
    padding: 10px;
    margin: 10px;
    border-radius: 5px;
    border: 1px solid $main-color;
    transition: background-color 0.5s ease-in-out;
    &:hover {
      background-color: $mainbis-color;
      transition: background-color 0.5s ease-in-out;
    }
  }
  .activeBut {
    background-color: $mainbis-color;
  }
}
.product-list {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
}
// CONtent menu
.menu_content {
  position: relative;
  max-width: 900px;
  height: auto;
  margin: 0 auto;
  padding: 20px;

  &::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: $mainbis-color;
    border-radius: 5px;
    opacity: 0.7;
    z-index: 1;
  }
}
.container_name:first-of-type {
  margin-top: 0;
}
.container_name {
  position: relative;
  width: 100%;
  height: 30px;
  z-index: 10;
  margin-top: 20px;
  margin-bottom: 15px;
  border-bottom: 1px dotted $main-color;
  display: flex;
  align-items: center;
  span {
    width: 85%;
    display: inline-block;
    font-size: 15px;
    font-weight: bold;
    color: $main-color;
    text-transform: capitalize;
  }
  p {
    text-transform: capitalize;
    margin-left: 20px;
    font-size: 14px;
    font-style: italic;
  }
}
.content_nav {
  position: relative;
  z-index: 5;
  list-style-type: none;
}
.item_content {
  margin-bottom: 25px;
  font-weight: 400;
  font-size: 16px;
  .flex_item {
    width: 100%;
    max-width: 650px;
    display: flex;
    flex-direction: column;
    span {
      width: fit-content;
    }
    span:first-child {
      font-weight: 600;
    }
    span:nth-child(2) {
      font-style: italic;
      font-size: 14px;
      margin: 10px 0;
    }
    span:last-child {
      font-size: 12px;
    }
  }
}
.container_desc_url {
  width: 100%;
  height: fit-content;
  p {
    font-size: 16px;
    line-height: 25px;
    letter-spacing: 1px;
    font-weight: 200;
    margin-bottom: 5px;
  }
  a {
    color: white;
    font-size: 16px;
    font-family: $second-font;
    font-weight: normal;
    text-decoration: none;
    &:hover {
      color: $main-color;
    }
  }
}
.container_flavour {
  position: relative;
  width: 100%;
  max-width: 280px;
  span {
    font-size: 16px;
  }
}
.content_flavour {
  width: fit-content;
  z-index: 5;
  list-style-type: none;
  margin-top: 15px;
}
@media screen and (min-width: 768px) {
  .menu_content {
    width: 620px;
  }
  .item_content {
    .flex_item {
      span:first-child {
        font-size: 18px;
        font-weight: 600;
      }
      span:nth-child(2) {
        font-size: 15px;
      }
      span:last-child {
        font-size: 14px;
      }
    }
  }
}
@media screen and (min-width: 830px) {
  .menu_content {
    width: 850px;
  }
  .container_name {
    height: 40px;
    span {
      font-size: 22px;
    }
  }
  .container_desc_url {
    p {
      font-size: 17px;
    }
    a {
      font-size: 17px;
    }
  }
}
//////////////////////////////////////////////////
///
///
