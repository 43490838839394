.container_header {
  position: relative;
  width: 100%;
  height: 64px;
  //   background-color: black;
  background-color: $test;
}
.header_flex {
  width: 100%;
  height: 100%;
  @include centerY;
  padding: 10px;
}
// MAIN
.header_menu {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

// BURGER
.container_emoji {
  width: 60px;
  height: 35px;
  border-radius: 5px;
  color: $main-color;
  @include centerXY; //   border: 1px solid $gold-active;
  //   background-color: $main-color;
}
//LOGO LA COOP
.container_logo {
  flex-basis: 33%;
  height: 100%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  p {
    text-align: center;
    color: $main-color;
    font-size: 20px;
    font-weight: 600;
    letter-spacing: 12px;
  }
  // .logo {
  //   width: 100%;
  //   height: 100%;
  // }
}
//  EMOJI
.emoji {
  width: 100%;
  height: 100%;
  //   color: $gold-active;
  color: $main-color;
  // color: white;
  cursor: pointer;
}
// RESEAU
.header_reseau {
  width: 60px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 1;
  a {
    width: 70%;
    height: 70%;
    :hover {
      color: $mainbis-color;
    }
  }
}
// NAVIGATION
.header_list {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  color: white;
  list-style-type: none;
  background-color: $test;
  padding: 0 10px;
  border-top: 1px solid $mainbis-color;
  -webkit-box-shadow: 0px 0px 15px -1px #000000;
  box-shadow: 0px 0px 15px -1px #00000015;

  .hearder_items {
    color: white;
    text-decoration: none;
    :hover {
      color: $main-color;
    }
  }
}
.mobile_nav {
  position: absolute;
  width: 100%;
  height: 250px;
  top: 100%;
  left: 0;
  z-index: 100;

  transform: translate(-150%);
  transition: transform 0.2s ease-in-out;
}
.show {
  display: block;
  position: absolute;
  width: 100%;
  height: 300px;
  top: 100%;
  left: 0;
  z-index: 100;
  transition: all 0.4s ease-in-out;
}
.bg_header {
  position: absolute;
  width: 100%;
  height: calc(100vh - 314px);
  z-index: 100;
  top: 100%;
  background-color: rgb(0, 0, 0, 0.6);
}
@media screen and (min-width: 768px) {
  .container_header {
    display: none;
  }
}
