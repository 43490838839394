@mixin centerXY {
  display: flex;
  justify-content: center;
  align-items: center;
}
@mixin centerY {
  display: flex;
  align-items: center;
}
@mixin ColCenterXY {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
@mixin CenterColConcept {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
