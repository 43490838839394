.container_concept {
  position: relative;
  @include ColCenterXY;
  width: 100%;
  height: 100%;
  color: $main-color;
  background-color: white;
}
.concept_opac {
  position: relative;
  width: 100%;
  height: calc(100vh - 64px);
  background-image: url("../../asset/musique2.jpg");
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  font-size: 21px;
  color: $main-color;

  .opac {
    position: absolute;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    background-color: rgba(0, 0, 0, 0.6);
  }
}
.concept_title {
  display: block;
  position: absolute;
  width: 100%;
  text-align: center;
  text-transform: uppercase;
  font-family: $font-test;
  letter-spacing: 2px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 10;
  opacity: 0;
  animation: appear 0.6s ease-out 0.5s forwards;
}
@keyframes appear {
  100% {
    opacity: 1;
  }
}
.scroll {
  position: absolute;
  width: 50px;
  height: 50px;
  top: 90%;
  left: 0;
  right: 0;
  margin: 0 auto;
  border-radius: 50%;
  background-color: $test;
  @include centerXY;
  z-index: 10;
  animation: bounce2 2s ease infinite;
}
@keyframes bounce2 {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-20px);
  }
  60% {
    transform: translateY(-15px);
  }
}
// SECTION US
.container_us {
  position: relative;
  width: 100%;
  height: 100vh;
  min-height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow-x: hidden;
  overflow-y: hidden;
  scroll-margin-top: 10px;
  padding-top: 55px;
  color: white;
  background: linear-gradient(
      310deg,
      rgba(90, 103, 90, 0.6) 10%,
      rgba(2, 0, 36, 0.6) 80%
    ),
    url("../../asset/laFamille.jpg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-attachment: fixed;
  // @include ColCenterXY;
}
.container_us_flex {
  width: 100%;
  height: fit-content;
  padding-bottom: 20px;
  position: sticky;
  top: 10%;
  bottom: 20%;
  h2 {
    text-transform: uppercase;
    padding-bottom: 25px;
    padding-top: 15px;
    letter-spacing: 2px;
    font-size: 1.188em;
    text-align: center;
    line-height: 30px;
  }
}
.us {
  max-width: 1100px;
  width: 100%;
  height: 550px;
  padding: 0 15px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.us_content {
  width: 100%;
  height: 350px;
  text-align: center;
  line-height: 25px;
  overflow-y: auto;
  background-color: rgba(0, 0, 0, 0.253);
  padding-top: 10px;
  &::-webkit-scrollbar {
    width: 5px;
    height: 0.5em;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #e9b535;
    border-radius: 3px;

    &:hover {
      background: #e9b535;
    }
  }
  h3 {
    text-align: center;
    padding-bottom: 10px;
  }
  .us_text {
    width: 100%;
    height: auto;
    span {
      display: block;
      margin-bottom: 10px;
      font-size: 17px;
    }
    p {
      margin-bottom: 10px;
      letter-spacing: 0.5px;
    }
    ul {
      .container_item_round {
        width: 100%;
        display: flex;
        text-align: left;
        div {
          width: 5px;
          height: 5px;
          margin: 10px 5px 0 0;
          flex-shrink: 0;
          background-color: #e9b535;
          border-radius: 100%;
        }
        li {
          margin-bottom: 15px;
        }
      }
    }
  }
}

.us_img {
  width: 100%;
  height: 100%;
  border-radius: 6px;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    border-radius: 6px;
  }
}
.container_carousel {
  width: 100%;
  height: 400px;
  margin-bottom: 15px;
}
@media screen and (min-width: 768px) {
  .container_us {
    padding-top: 50px;
    justify-content: center;

    h2 {
      font-size: 1.9em;
      padding-bottom: 50px;
    }
  }
  .concept_opac {
    font-size: 30px;
  }
  .concept_title {
    letter-spacing: 5px;
  }
  .us {
    flex-direction: row;
    column-gap: 30px;
    margin: 0 auto;
    height: 450px;
  }
  .us_img {
    width: 100%;
    height: 100%;
    border-radius: 6px;
  }

  .us_content {
    flex-basis: 40%;
    flex-grow: 0;
    height: 100%;
    .us_text {
      padding-top: 20px;
    }
  }
  .container_carousel {
    width: 50%;
    height: 100%;
    flex-basis: 50%;
    flex-grow: 1;
    flex-shrink: 0;
  }
}
@media screen and (min-width: 1440px) {
  .container_us {
    height: calc(100vh - 64px);
    h2 {
      padding-bottom: 80px;
    }
  }
  .us_content {
    height: fit-content;
    align-self: center;
  }
}
