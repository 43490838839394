.sideInfos {
  position: fixed;
  z-index: 80;
  left: 0;
  top: 80%;
  background-color: $mainbis-color;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  -webkit-border-top-right-radius: 10px;
  -webkit-border-bottom-right-radius: 10px;
  -moz-border-radius-topright: 10px;
  -moz-border-radius-bottomright: 10px;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  border: 1px solid $test;
  transition: all 1s linear;

  .emoji {
    width: 30px;
    height: 30px;
  }
}

.sideInfosOPen {
  width: 50px;
  height: 80px;
  transition: all 0.2s linear;
}
.sideInfosClose {
  width: 35px;
  height: 60px;
  transition: all 0.2s linear;
}
