.mySwiper {
  width: 100%;
  height: 100%;
}
.swiper-slide {
  width: 100%;
  height: 100%;
}
.swiper-button-next,
.swiper-button-prev {
  fill: $main-color !important;
  color: $main-color !important;
}
