.container_activity {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 25px;
  align-items: center;

  .container_img_activity {
    width: 250px;
    height: 350px;
    border-radius: 5px;
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
      border-radius: 5px;
    }
  }

  .container_content_activity {
    width: 100%;
    max-width: 500px;
    padding: 0 10px 0 10px;
    margin: 0 auto;
    p:last-child {
      color: white;
      text-align: left;
      margin-top: 15px;
    }
  }
}
@media screen and (min-width: 768px) {
  .container_activity {
    flex-direction: row;
    padding: 0 20px;
    .container_img_activity {
      width: 70%;
      height: 100%;
    }
  }
}
