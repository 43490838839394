.rgpd {
  width: 100%;
  padding: 30px 10px;
  background-color: $mainbis-color;
  color: white;
  display: flex;
  flex-direction: column;
  gap: 40px;
}
.first_second_rgpd {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 5px;
  p:first-child {
    flex-shrink: 0;
    font-weight: 600;
    color: $main-color;
  }
}
.container_list_rgpd {
  display: flex;
  flex-direction: column;
  gap: 20px;
  .list_rgpd_title {
    color: $main-color;
    font-size: 1rem;
  }
}
