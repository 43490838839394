.container_event {
  width: 100%;
  height: 100%;
  min-height: 100vh;
  z-index: 1;
  color: white;
  padding: 20px 10px;
  background-image: url("../../asset/galerePict2.JPG");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-attachment: fixed;
  position: relative;
  &::after {
    content: " ";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgb(0, 0, 0, 0.9);
    z-index: 1;
    opacity: 0.6;
    background-size: cover;
  }
}
.content_event {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  z-index: 5;
  list-style-type: none;
  gap: 19px;
}
.item_event {
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  height: 370px;
  margin: 10px 0 10px 0px;
}
.event_img {
  flex: none;

  position: relative;
  width: 100%;
  height: 250px;
  border-radius: 5px;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }
  span {
    position: absolute;
    right: 0;
    bottom: 0;
    text-transform: uppercase;
    font-size: 20px;
    font-weight: bold;
    padding: 15px;
    letter-spacing: 2px;
  }
  .event_desc {
    position: absolute;
    display: none;
    width: 100%;
    bottom: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.8);
  }
  &:hover {
    .event_desc {
      display: block;
      padding: 15px;
      animation: slide 0.7s forwards;
    }
  }
}
.reseau {
  width: 100%;
  height: fit-content;
  display: flex;
  align-items: center;
  .emoji_event {
    cursor: pointer;
    width: 20px;
    height: 20px;
    color: $main-color;
    &:hover {
      color: $mainbis-color;
    }
  }
}
.event_detail {
  flex-grow: 1;
  width: 100%;
  display: flex;
  padding: 5px;
  margin-top: 10px;
  > span:nth-of-type(2) {
    width: fit-content;
    font-weight: 600;
    padding: 5px 10px;
    background-color: $main-color;
    border-radius: 5px;
    align-self: flex-end;
  }
  > :first-child {
    border-top: 1px solid $main-color;
    border-bottom: 1px solid $main-color;
    @include ColCenterXY;
    justify-content: center;
    letter-spacing: 2px;
    font-weight: 500;
    span {
      padding: 5px;
    }
  }
  > :nth-child(2) {
    width: fit-content;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-left: 20px;
    font-size: 14px;
    :first-child {
      display: block;
      font-size: 15px;
      letter-spacing: 2px;
      font-weight: 500;
    }
    :nth-child(3) {
      font-weight: 600;
      letter-spacing: 2px;
    }
  }
}

@keyframes slide {
  from {
    height: 0;
  }
  to {
    height: 100%;
  }
}
.error {
  position: relative;
  z-index: 5;
  text-align: center;
}
@media screen and (min-width: 528px) {
  .item_event {
    flex-basis: calc(50% - 10px);
  }
}
@media screen and (min-width: 1024px) {
  .item_event {
    flex-basis: calc(33% - 10px);
  }
}
.emoji_link {
  cursor: pointer;
  width: 20px;
  height: 20px;
  color: $main-color;
  &:hover {
    color: $mainbis-color;
  }
}
