.container_archive {
  width: 100%;
  min-height: 100vh;
  background-color: $mainbis-color;
}
.archive-content {
  width: 100%;
  height: 100%;
  padding: 50px 10px 0px 10px;
  display: flex;
  flex-direction: column;
  gap: 50px;
  h1 {
    text-transform: uppercase;
    text-align: center;
    color: $main-color;
    letter-spacing: 3px;
  }
}
.archive_loading {
  text-align: center;
  font-size: 1rem;
  color: white;
}
.grid_container {
  position: relative;
  z-index: 60;
  display: grid;
  max-width: 1100px;
  margin: 0 auto;
  padding-bottom: 20px;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-rows: 65px 1fr 1fr 1fr;
  gap: 50px 0px;
  grid-template-areas:
    "timeline timeline timeline timeline"
    "archive archive archive archive"
    "archive archive archive archive"
    "archive archive archive archive";
}
.timeline {
  grid-area: timeline;
  position: sticky;
  top: calc(64px + 0%);
  z-index: 100;
  background-color: $mainbis-color;
  display: flex;
  overflow-x: auto;
  width: 100%;
  height: auto;
  list-style-type: none;
  gap: 25px;
  color: $main-color;
  padding: 10px;
  &::-webkit-scrollbar {
    height: 8px;
    width: 8px;
    background: $test;
    border-radius: 10px;
  }
  &::-webkit-scrollbar-thumb {
    background: $main-color;
    border-radius: 10px;
  }
  .timeline_item {
    background-color: $main-color;
    color: $mainbis-color;
    border: 1px solid $test;
  }
  li {
    background-color: $mainbis-color;
    border: 1px solid $main-color;
    width: fit-content;
    height: fit-content;
    padding: 5px;
    border-radius: 5px;
    font-family: $second-font;
    cursor: pointer;
  }
}

.archive {
  position: relative;
  width: 100%;
  grid-area: archive;
  display: flex;
  flex-direction: column;
  gap: 80px;
  background-color: rgba(0, 0, 0, 0.162);
  border-radius: 5px;
  padding: 10px;
  // test
  div:nth-of-type(even) {
    align-self: flex-end;
  }

  .test {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    scroll-margin-top: 135px;

    .frise {
      display: none;
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 100%;
      text-align: center;
      padding: 20px;
      .year_absolute {
        font-size: 1.5rem;
        font-weight: 600;
        color: $main-color;
      }
      .caption_absolute {
        color: white;
        font-family: $lacoop;
        font-size: 1.2rem;
      }
    }
    .two,
    .five,
    .seven,
    .nine,
    .eleven,
    .fourteen,
    .sixteen,
    .eighteen,
    .twenty-two,
    .twenty-five {
      left: -100%;
    }
  }

  // Fin test
  .block_archive {
    position: relative;
    z-index: 10;
    width: 100%;
    border-radius: 5px;
    background-color: #5a675a;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    gap: 15px;
    padding: 10px;
    color: black;
    text-align: center;

    .year_block {
      color: $main-color;
      font-weight: 600;
      font-size: 1.1rem;
    }
    .block_content {
      width: 100%;
      line-height: 23px;
      text-align: initial;
      font-size: 1rem;
      color: white;

      ul {
        list-style-type: none;
        li {
          margin-bottom: 5px;
        }
      }
      p {
        font-family: $second-font;

        margin-top: 15px;
      }
      p:first-child {
        margin-top: 0;
      }
    }
    .archive_block_image {
      width: 100%;
      height: fit-content;
      border-radius: 5px;

      .image_block {
        width: 100%;
        height: 100%;
        max-height: 495px;
        border-radius: 5px;

        img {
          width: 100%;
          height: 100%;
          border-radius: 5px;
          object-fit: contain;
        }
      }
    }
    .caption_block {
      color: white;
      font-size: 1.2rem;
      font-family: $lacoop;
    }
  }
}

.see_more {
  cursor: pointer;
  color: $main-color;
  font-weight: 600;
  &:hover {
    color: $mainbis-color;
  }
}
.hide_content {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.8s linear;
}
.show_content {
  display: flex;
  flex-direction: column;
  gap: 15px;
  max-height: 2000px;
  overflow: hidden;
  transition: max-height 0.8s linear;
}

@media screen and (min-width: 768px) {
  .archive {
    .test {
      width: 50%;
      .frise {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 15px;
      }
      .year_block {
        display: none;
      }
      .more_year {
        display: block;
      }
      .caption_block {
        display: none;
      }
    }
  }
}
@media screen and (min-width: 1024px) {
  .grid_container {
    height: auto;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-rows: 1fr 100px;
    gap: 0px 10px;
    grid-template-areas:
      ". archive archive ."
      ". . . ."
      ".  . . ."
      ". . . .";
  }
  .timeline {
    flex-direction: column;
    overflow: initial;
    width: max-content;
    height: 500px;
    grid-area: unset;
    top: 14%;
    overflow-y: scroll;
    align-items: center;
    align-self: flex-start;
  }
  .archive {
    width: 860px;
    max-width: 900px;
    height: 100%;
    .test {
      scroll-margin-top: 80px;
    }
  }
}
