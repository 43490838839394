.container_producteur {
  width: 100%;
  min-height: 100vh;
  padding: 50px 10px;
  background-color: $mainbis-color;
}
.section_producteur {
  width: 100%;
  height: 100%;
  max-width: 800px;
  margin: 0 auto;
  padding: 50px 10px 30px 10px;
  display: flex;
  flex-direction: column;
  gap: 50px;
  background-color: rgba(0, 0, 0, 0.162);
  p {
    color: $main-color;
    font-weight: 600;
    font-size: 1rem;
    text-align: center;
  }
  h1 {
    text-align: center;
    color: $main-color;
  }
}
.container_producteur_item {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 30px;
  .list_producteur {
    display: flex;
    flex-direction: column;
    gap: 10px;
    list-style-type: none;
    .item_list_producteur {
      width: 100%;
      width: 150px;
      margin: 0 auto;
      text-decoration: none;
      display: flex;
      gap: 15px;

      .emoji_producteur {
        width: 20px;
        height: 20px;
        color: white;
      }
      .link_list_producteur {
        width: fit-content;
        word-wrap: break-word;
        text-decoration: none;
        color: white;
        font-family: $lacoop;

        &:hover {
          color: $main-color;
        }
      }
    }
  }
}
