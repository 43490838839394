.notfound_container {
  color: $main-color;
  font-size: 19px;
  background-color: $mainbis-color;
  width: 100%;
  height: calc(100vh - 64px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .notfound_flex {
    padding: 0 10px;
    max-width: 800px;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    p {
      margin-bottom: 20px;
    }
    .notfound_button {
      width: 250px;
      padding: 10px;
      color: $main-color;
      background-color: $mainbis-color;
      border: 1px solid $gold-active;
      &:hover {
        background-color: $gold-active;
        color: $mainbis-color;
      }
    }
  }
}
