.image-gallery {
  position: relative;
  z-index: 5;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  list-style-type: none;
  &::after {
    content: "";
    flex-grow: 999;
  }
}

.image-gallery > li {
  flex: 1 1 auto;
  height: 330px;
  position: relative;
}

.image-gallery li img {
  object-fit: cover;
  width: 100%;
  height: 100%;
  vertical-align: middle;
  border-radius: 5px;
}
.container__video-bar {
  position: relative;
  z-index: 5;
  width: 100%;
  height: 450px;
  margin-bottom: 10px;
  video {
    width: 100%;
    height: 100%;
  }
}
