.container_desk_header {
  display: none;
}
header {
  position: sticky;
  top: 0;
  z-index: 100;
}
@media screen and (min-width: 768px) {
  .container_desk_header {
    display: block;
    position: relative;
    width: 100%;
    height: 64px;
    background-color: $test;
  }
  //CONTAINER
  .container_desk_flex {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    padding: 10px;
  }
  // LOGO
  .container_desk_logo {
    cursor: pointer;
    width: 80px;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    p {
      display: inline-block;
      text-align: center;
      color: $main-color;
      font-size: 1rem;
      font-weight: 600;
      letter-spacing: 8px;
      width: 100%;
    }
    // .logo_desk {
    //   width: 100%;
    //   height: 100%;
    // }
  }
  // NAV
  .nav_desk {
    width: 100%;
    height: 100%;
    padding: 0 20px;
    font-size: 12px;
  }
  .header_list_desk {
    max-width: 980px;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    list-style-type: none;
    margin: 0 auto;
    // font-size: 12px;

    li {
      text-align: center;
      :hover {
        color: $main-color;
      }
    }
    .desk_items {
      display: flex;
      flex-direction: column;
      text-decoration: none;
      color: white;
      text-align: center;

      .item_desk_select {
        color: $main-color;
      }
    }
  }
  .desk_reseau {
    width: 70px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 1;
    a {
      width: 30px;
      height: 30px;

      :hover {
        color: $mainbis-color;
      }
    }
  }
}
@media screen and (min-width: 1024px) {
  .container_desk_flex {
    padding: 10px 20px;
  }
  .nav_desk {
    font-size: 16px;
  }
}
@media screen and (min-width: 1440px) {
  .nav_desk {
    font-size: 18px;
  }
}
@media screen and (min-width: 2560px) {
  .nav_desk {
    font-size: 22px;
  }
  .header_list_desk {
    max-width: 1200px;
  }
}
