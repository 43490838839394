
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: $main-font;
}
html {
  height: 100%;
  min-height: 100%;
  height: 100%;
}
body {
  width: 100%;
  height: 100%;

  // min-height: 100%;
  min-height: 100%;
}
