.title_mentions_legale {
  font-size: 1.4rem;
  margin-bottom: 20px;
  color: $main-color;
}
.container_mentions {
  width: 100%;
  padding: 30px 10px;
  background-color: $mainbis-color;
  color: white;
  display: flex;
  flex-direction: column;
  gap: 40px;
}
.link_mention_legale {
  text-decoration: none;
  color: $main-color;
}
.mentions_section {
  display: flex;
  flex-direction: column;
  gap: 15px;
  max-width: 750px;
  margin: 0 auto;
}
